<template>
  <div class="ori-person">
    <ori-header />
    <match-filter />

    <div class="person-list-wrap" @click="$router.push('/person-detail')">
      <div class="person-content w1200">
        <el-row :gutter="20">
          <el-col :span="8" v-for="i in list" :key="i.id">
            <div class="person-box">
              <div class="avatar">
                <img :src="i.avatarBig" alt="" srcset="" />
              </div>
              <div class="info">
                <div class="name solo-row">
                  {{ i.cname }}
                  <span>{{ i.name }}</span>
                </div>
                <div class="info-row solo-row">
                  <span>学位：</span>
                  <span>{{ i.xlTag }}</span>
                </div>
                <div class="info-row solo-row">
                  <span>职业模式：</span>
                  <span>{{ i.msTag.slice(0, 3).join("、") }}</span>
                </div>
                <div class="info-row solo-row">
                  <span>专业领域：</span>
                  <span>{{ i.hyTag }}</span>
                </div>
                <div class="info-row solo-row">
                  <span>浏览量：</span>
                  <span>{{ i.lookTime }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="w1200 pagination-wrap">
      <ori-pagination />
    </div>

    <page-foot />
  </div>
</template>

<script>
import { cuCardData } from "@/Mock";
import PageFoot from "../../../components/ori-com/PageFoot.vue";
import MatchFilter from "../../../components/ori-com/Select/MatchFilter.vue";
export default {
  components: { MatchFilter, PageFoot },
  data() {
    return {
      list: cuCardData.list,
    };
  },
};
</script>

<style lang="scss" scoped>
.ori-person {
  width: 100%;
  overflow: hidden;
  background-color: #f9f9f9;

  .person-list-wrap {
    margin-top: 20px;

    .person-box {
      cursor: pointer;
      background-color: #fff;
      margin-bottom: 20px;
      padding: 23px 20px;

      display: flex;
      align-items: flex-start;

      &:active,
      &:hover {
        box-shadow: 0 0 15px #ddd;
        .avatar img {
          transform: scale(1.2);
        }
      }

      .avatar {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        overflow: hidden;
        img {
          transition: all 0.8s ease;
        }
      }

      .info {
        margin-left: 12px;
        .name {
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: 700;
          color: #333333;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 220px;
          white-space: nowrap;

          span {
            margin-left: 10px;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
          }
        }

        .info-row {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          margin-bottom: 4px;
          span:nth-child(1) {
            color: #999;
          }
        }
      }
    }
  }

  .pagination-wrap {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px 0;
    display: flex;
    justify-content: center;
  }
}
</style>